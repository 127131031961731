<template>
  <div class="container"
       v-loading="loading">
    <el-card class="register"
             v-if="!isRegister">
      <el-row type="flex"
              justify="center"
              class="title">Please fill in the information</el-row>
      <el-form ref="registerForm"
               :model="form"
               label-width="80px"
               :rules="formRule">
        <el-form-item label="Name:"
                      prop="username">
          <el-input ref="username"
                    v-model="form.username"
                    size="mini"
                    placeholder="Please enter your name"></el-input>
        </el-form-item>
        <el-form-item label="Email:"
                      prop="email">
          <el-input ref="email"
                    v-model="form.email"
                    size="mini"
                    placeholder="Please enter your email"></el-input>
        </el-form-item>
        <el-row type="flex"
                justify="end">
          <el-button size="mini"
                     type="primary"
                     class="linkedin"
                     @click="linkedIn"></el-button>
          <el-button size="mini"
                     type="primary"
                     @click="submit">Next</el-button>
        </el-row>
      </el-form>
    </el-card>

    <el-card class="videos"
             :body-style="{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
      }"
             v-if="isAnswer">
      <img alt="Vision"
           src="../../assets/logo.png" />
      <div v-if="send">Sharing link has been sent to your email.</div>
      <div v-else-if="control">It looks like you don’t have permission to access this page.</div>
      <div v-else-if="expired">This link has expired. Please contact the project manager to get a new one.</div>
      <div v-else>A Better Vision Powered by Data.</div>

      <!-- <div class="content">
        <template v-for="project in projects" :key="project.id">
          <el-card
            v-if="project.video_count > 0"
            @click="showProject(project)"
            class="content-item"
          >
            <el-row class="content-title">{{ project.name }}</el-row>
            <el-row class="content-title"
              >Number of videos:{{ project.video_count }}</el-row
            >
            <el-row class="content-title" type="flex" justify="end">{{
              project.created_at
            }}</el-row>
          </el-card>
        </template>
      </div>
      <el-pagination
        @current-change="changePage"
        class="pagination"
        :hide-on-single-page="false"
        :total="pagination.total"
        :page-size="pagination.size"
        :current-page="pagination.current"
        layout="prev, pager, next"
      >
      </el-pagination> -->
    </el-card>
  </div>
</template>

<script>
// require('@/utils/in.js')
// import { getProjects } from '@/api/project'
export default {

  data () {
    const validate = (rule, value, callback) => {
      //   console.log(value.search(/.*@.*\..*/))
      if (value.search(/.*@.*\..*/) === -1) {
        callback(new Error('Please enter your vaild email!'))
      } else {
        callback()
      }
    }
    return {
      loading: true,
      videoTitle: '',
      videoVisible: false,
      isRegister: false,
      isAnswer: false,
      projects: [],
      info: {},
      form: {
        username: '',
        email: '',
      },
      formRule: {
        username: [
          { required: true, trigger: 'blur', message: "Please enter your name!" }
        ],
        email: [
          { required: true, trigger: 'blur', validator: validate },
        ]
      },
      pagination: {
        total: 0,
        current: 1,
        size: 10,
      },
      control: false,
      expired: false,
      send: false
    }
  },
  inject: ["reload"],
  mounted () {
    let query = this.$route.query
    if (query.control == 1) {
      this.control = true
    }
    if (query.expired == 1) {
      this.expired = true
    }
    if (query.send == 1) {
      this.send = true
    }
    // console.log(this.$store.getters.token)
    // console.log(123123)
    // console.log(query)
    if (query.code) {
      // console.log(query)
      this.registerByLinkedIn(query.code)
    } else {
      if (this.$store.getters.token) {
        this.isRegister = true
        this.$store.dispatch('user/getInfo').then(res => {
          this.info = res.data
          this.isAnswer = true
          this.loading = false
          if (this.$route.query.redirect) {
            this.$router.push("/" + this.$route.query.redirect)
          }
        }).catch(() => {
          this.isRegister = false
          this.loading = false
        })
      } else {
        this.loading = false
      }
      // console.log(this.loading)
    }


    // this.fetchData({
    //   page: this.pagination.current,
    //   size: this.pagination.size,
    // })
  },
  methods: {
    linkedIn () {
      let state = ''
      if (this.$route.query.redirect) {
        state = '&state=' + this.$route.query.redirect
      }
      //   console.log(state, this.$route.query.redirect)
      let oauth = 'https://www.linkedin.com/oauth/v2/authorization?response_type=code&client_id=86ourwg4vgtmnn&redirect_uri=https://dev-vision.asense.tech&scope=r_liteprofile%20r_emailaddress' + state
      window.location.href = oauth
    },
    registerByLinkedIn (code) {
      this.loading = true
      //   console.log(code)
      this.$store.dispatch('user/registerByLinkedIn', code).then(() => {
        // console.log(this.$route.query.state)
        // console.log(this.$route.query.state != 'undefined')
        if (this.$route.query.state && this.$route.query.state != 'undefined') {

          this.$router.push("/" + this.$route.query.state)
        } else {
          this.$router.push("/?t=" + new Date().getTime())
          // this.reload()
        }
        this.loading = false
      }).catch(err => {
        // console.log(err)
        this.$message.warning(err.message)
        this.loading = false
      })
    },
    // fetchData (params = {}) {
    //   getProjects(params).then(res => {
    //     this.isAnswer = true
    //     this.loading = false
    //     this.projects = res.data.list
    //     this.pagination.size = res.data.size
    //     this.pagination.total = res.data.total
    //     this.pagination.current = res.data.page
    //   }).catch(err => {
    //     console.log(err)
    //     this.loading = false
    //   })
    // },
    // changePage (page) {
    //   this.fetchData({
    //     page: page,
    //     size: this.pagination.size
    //   })
    // },
    submit () {
      this.$refs['registerForm'].validate((valid) => {
        if (valid) {
          this.$store.dispatch('user/register', this.form).then(() => {
            sessionStorage.setItem("Client-Email", this.form.email)
            if (this.$route.query.redirect && this.$route.query.redirect != 'undefined') {
              this.$router.push("/" + this.$route.query.redirect)
            } else {
              this.$router.push("/")
              this.reload()
            }
          })
        } else {
          return false
        }
      })
    },
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style  scoped>
.linkedin {
  width: 200px;
  background: url(~@/assets/linkedin.png) no-repeat;
  background-size: 100% 100%;
  border: none;
}
.container {
  min-height: 100%;
  width: 100%;
  background-color: #2d3a4b;
  overflow: hidden;
  background-image: url(~@/assets/bg.png);

  background-position: center center;
  background-size: cover;

  display: flex;
  justify-content: center;
  align-items: center;
}
.register {
  margin-top: -100px;
  width: 480px;
}
.videos {
  position: absolute;
  top: 20%;
  padding: 50px;
  /* width: 88%; */
  display: flex;
  justify-content: center;
  align-items: center;
}
.title {
  font-size: 24px;
  margin: 20px 0;
}
.pagination {
  text-align: center;
}
.content {
  display: flex;
  flex-wrap: wrap;
}
.content-item {
  width: 25%;
  margin: 5px;
  cursor: pointer;
}
.content-title {
  color: #606266;
  font-size: 14px;
  font-weight: 500;
}

.videos img {
  width: 150px;
}

.videos div {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 30px;
  font-size: 22px;
}
</style>
